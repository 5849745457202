/* stylelint-disable at-rule-no-unknown */
@tailwind base;

@layer base {
  html {
    font-family: 'Amiko', sans-serif;
  }

  h1 {
    @apply text-8xl font-montsarrat;
  }

  h2 {
    @apply text-3xl sm:text-4xl md:text-5xl font-montsarrat m-0 whitespace-nowrap font-bold;
  }

  h3 {
    @apply text-2xl sm:text-3xl md:text-4xl font-montsarrat;
  }

  h4 {
    @apply text-xl sm:text-2xl md:text-3xl font-montsarrat;
  }

  h5 {
    @apply text-lg sm:text-xl md:text-2xl font-montsarrat m-0 font-bold;
  }

  h6 {
    @apply text-base sm:text-lg md:text-xl font-montsarrat;
  }

  p {
    @apply text-xs sm:text-sm md:text-base;
  }

  a {
    @apply text-base text-primary-main no-underline font-bold	hover:text-primary-light text-center;
  }

  .custom-menu-bar {
    @apply custom-box-shadow z-50 flex items-start lg:items-center justify-between px-10 py-3 w-full bg-gradient-to-br from-tertiary-dark to-tertiary-main md:px-24 lg:px-32 xl:px-72;
  }

  .custom-title {
    @apply text-primary-dark font-bold mt-40 mb-28;
  }

  .custom-header {
    @apply text-center mt-20 mb-10 sm:mt-40 sm:mb-20 w-full lg:w-8/12;
  }

  .custom-header-title {
    @apply text-primary-dark font-bold mt-0 mb-4 whitespace-normal;
  }

  .custom-contents {
    @apply flex flex-col flex-1  w-full lg:grid lg:grid-flow-col lg:grid-cols-3 place-items-center lg:col-auto lg:gap-2;
  }

  .custom-contents-item {
    @apply flex flex-1 flex-col items-center w-72 lg:w-full lg:pb-0 pb-5;
  }

  .custom-card {
    @apply w-72 mb-1 bg-white shadow-md rounded-md;
  }

  .custom-card-content {
    @apply flex flex-col justify-between p-4 h-full text-left;
  }

  .custom-card-text {
    @apply flex flex-col justify-start text-left text-black;
  }

  .custom-card-btn {
    @apply bg-primary-dark text-white rounded-lg py-1.5 hover:opacity-80 hover:text-white hover:bg-primary-light mt-1.5;
  }

  .custom-text-shadow {
    text-shadow: 0 0 3px #e6e6e6;
  }

  .custom-img {
    object-fit: contain;
    width: 2rem;
    margin: 0 10px 4px 10px;
  }

  .custom-img:hover {
    color: #fff;
    opacity: 0.5;
  }

  .custom-anchor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 4px;
  }

  .custom-anchor:hover {
    transform: translateY(-0.1875em);
  }

  .custom-box-shadow {
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.5);
  }
}

@tailwind components;
@tailwind utilities;
